import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { useEffectOnce } from "shared/lib/hooks";
import { EmailTemplateDeleteModal } from "@/components/modals/email-template-delete-modal";
import { EmailTemplatesList } from "@/modules/email-templates-sidebar/content/email-templates-list";
import { EmailTemplateWorkspace } from "@/modules/email-templates-sidebar/content/email-template-workspace";
import {
  useCreateEmailDraft,
  useDeleteTemplate,
  useFetchEmailTemplatesList,
} from "@/modules/email-templates-sidebar/queries";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

export const EmailTemplatesSidebarContent = () => {
  const { campaign, account, focusedContact } = useAccountDetailsContext();
  const { data: templates } = useFetchEmailTemplatesList();
  const { mutateAsync: deleteTemplateAsync } = useDeleteTemplate();
  const { mutateAsync: createEmailDraftAsync } = useCreateEmailDraft();

  const [activeTemplateId, setActiveTemplateId] = useState<string>();
  const [isEditMode, setEditMode] = useState<boolean>();
  const [emailId, setEmailId] = useState<string>();
  const [senderEmail, setSenderEmail] = useState<string>();

  const activeTemplate = useMemo(
    () =>
      templates?.data?.email_templates?.find((t) => t.id === activeTemplateId),
    [activeTemplateId, templates]
  );

  // When user opens the email sidebar, a draft is generated.
  // This endpoint returns an emailId, which is required later for AI generation or sending email.
  const createEmailDraft = useCallback(async () => {
    if (campaign?.id && account?.id && focusedContact?.id) {
      const resp = await createEmailDraftAsync({
        parameters: {
          campaign_id: campaign.id,
          account_id: account.id,
          contact_id: focusedContact.id,
        },
      });

      if (resp.status === 200) {
        setEmailId(resp.data.email_id);
        setSenderEmail(resp.data.from_email);
      } else if (resp.status === 401) {
        toast.error("The prospect requested not to email");
      }
    }
  }, [focusedContact, campaign, account]);

  // by default, set first template as active
  // active template is set to undefined when isEditMode is true because that implies creating a new template
  useEffect(() => {
    if (!activeTemplateId && !isEditMode) {
      setActiveTemplateId(templates?.data?.email_templates?.[0]?.id);
    }
  }, [templates?.data?.email_templates, activeTemplateId, isEditMode]);

  useEffectOnce(() => {
    createEmailDraft();
  });

  return (
    <>
      <div className="flex h-full">
        <EmailTemplatesList
          className="h-full w-[420px]"
          templates={templates?.data?.email_templates}
          activeTemplateId={activeTemplateId}
          setActiveTemplateId={setActiveTemplateId}
          setEditMode={setEditMode}
        />

        <EmailTemplateWorkspace
          className="flex-1 overflow-scroll"
          emailId={emailId}
          senderEmail={senderEmail}
          template={activeTemplate}
          isEditMode={isEditMode}
          setActiveTemplateId={setActiveTemplateId}
          setEditMode={setEditMode}
        />
      </div>

      <EmailTemplateDeleteModal
        name={activeTemplate?.name}
        onConfirm={() => {
          if (activeTemplateId) {
            deleteTemplateAsync(activeTemplateId);
            setActiveTemplateId(undefined);
          }
        }}
      />
    </>
  );
};
