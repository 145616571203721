import { useMemo, useRef, useState } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { TextField } from "shared/ui/user-input";
import { inputBadgesToString } from "shared/ui/user-input/badge-input/helper";
import { BadgeI, BadgeValidationI } from "shared/ui/user-input/badge-input";
import { EmailEditor, EmailEditorContentI } from "shared/ui/email-editor";
import {
  AttachmentI,
  EmailTemplateI,
  SendEmailRequestParamsI,
  SendEmailWithAIRequestParamsI,
} from "@/api/routes/email";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useValidateEmails } from "@/components/modules/email-templates-sidebar/queries";
import { EmailAttachmentsSection } from "@/modules/email-templates-sidebar/shared/email-attachments-section";
import { EmailRecipientsSection } from "./recipients-section";
import { EmailFollowUpsSection } from "./follow-ups-section";
import { ComposeWithAiButton } from "./compose-with-ai-btn";

const EnhancedTextField = TextField(Field);

export const QUICK_SEND_AI_EMAIL_BUTTON_ID = "quick_send_ai_email_button";

const EmailFormValidation = Yup.object().shape({
  to: Yup.array().required("Required field").min(1, "At least 1 is required"),
  cc: Yup.array(),
  bcc: Yup.array(),
  subject: Yup.string()
    .trim()
    .required("Required field")
    .max(500, "Subject cannot exceed 500 characters"),
});

export interface EmailFormI {
  to: BadgeI[] | string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  max_follow_ups: number;
  follow_up_interval_days: number;
}

export const EmailForm = ({
  template,
  senderEmail,
  subject,
  initialBody,
  attachmentOptions = [],
  onComposeWithAI = () => {},
  isAIGenerationComplete,
  onSend = async () => false,
  onQuickSendWithAI = async () => false,
  isSendingEmail,
  isSendEmailSuccess,
}: {
  template: EmailTemplateI;
  senderEmail?: string;
  subject?: string;
  initialBody?: string;
  attachmentOptions?: AttachmentI[];
  onComposeWithAI?: () => void;
  isAIGenerationComplete?: boolean;
  onSend?: (data: SendEmailRequestParamsI) => Promise<boolean>;
  onQuickSendWithAI?: (data: SendEmailWithAIRequestParamsI) => Promise<boolean>;
  isSendingEmail?: boolean;
  isSendEmailSuccess?: boolean;
}) => {
  const { focusedContact } = useAccountDetailsContext();
  const { mutateAsync: validateEmailsAsync } = useValidateEmails();
  const [isEmailsValid, setIsEmailsValid] = useState(false);
  const formikRef = useRef<FormikProps<EmailFormI>>(null);
  const [bodyContent, setBodyContent] = useState<EmailEditorContentI>();
  const [bodyError, setBodyError] = useState<string>();
  const [isSendFollowUps, setIsSendFollowUps] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState<AttachmentI[]>(
    template.attachments || []
  );

  // the "to" field uses <BadgeInput />, so the default value for the "to" field needs to be type BadgeI
  const form: EmailFormI = useMemo(
    () => ({
      to: [
        {
          label: focusedContact?.email || "",
          value: focusedContact?.email || "",
        },
      ],
      cc: [],
      bcc: [],
      subject: subject || "",
      max_follow_ups: 2,
      follow_up_interval_days: 2,
    }),
    [subject, focusedContact]
  );

  const handleEmailValidation = async (emails: string[]) => {
    const resp = await validateEmailsAsync(emails);

    const validationData: BadgeValidationI = {
      validValues: [],
      invalidValues: [],
    };

    if (resp.status === 200) {
      validationData.validValues = resp.data.valid_emails;
      validationData.invalidValues = resp.data.invalid_emails;
    }

    setIsEmailsValid(
      validationData.invalidValues.length === 0 &&
        validationData.validValues.length > 0
    );
    return validationData;
  };

  const handleAttachmentsChange = (attachment: AttachmentI) => {
    let updatedAttachments = selectedAttachments;

    if (selectedAttachments.filter((v) => v.id === attachment.id).length) {
      updatedAttachments = updatedAttachments.filter(
        (v) => v.id !== attachment.id
      );
    } else {
      updatedAttachments.push(attachment);
    }

    setSelectedAttachments([...updatedAttachments]);
  };

  const handleQuickSend = () => {
    const formData = formikRef.current?.values;

    const isValidForQuickSend = formData?.to;
    if (!isValidForQuickSend) {
      console.log("'To' field cannot be empty for Quick Send to work.");
      return;
    }

    onQuickSendWithAI({
      to: inputBadgesToString(formData.to),
      cc: inputBadgesToString(formData.cc),
      bcc: inputBadgesToString(formData.bcc),
      max_follow_ups: isSendFollowUps
        ? parseInt(formData.max_follow_ups.toString())
        : 0,
      follow_up_interval_days: parseInt(
        formData.follow_up_interval_days.toString()
      ),
    });
  };

  const onFormSubmitHandler = (formData: EmailFormI) => {
    setBodyError(undefined);

    if (!bodyContent?.plainText?.trim() || !bodyContent?.htmlText?.trim()) {
      setBodyError("Email body is required");
      return;
    }

    const preparedData: SendEmailRequestParamsI = {
      to: inputBadgesToString(formData.to),
      cc: inputBadgesToString(formData.cc),
      bcc: inputBadgesToString(formData.bcc),
      subject: formData.subject,
      body: bodyContent.plainText,
      raw_body: bodyContent.htmlText,
      attachments: selectedAttachments.map((v) => v.id),
      max_follow_ups: isSendFollowUps
        ? parseInt(formData.max_follow_ups.toString())
        : 0,
      follow_up_interval_days: parseInt(
        formData.follow_up_interval_days.toString()
      ),
    };

    onSend(preparedData);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={form}
      validationSchema={EmailFormValidation}
      onSubmit={onFormSubmitHandler}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <Form className="flex h-full flex-col justify-between">
          <section>
            <div className="mb-5">
              <div className="mb-3 typography-header-6">{template.name}</div>

              <div className="mb-3 typography-body-4-medium">
                <span className="gradient-text">Compose with AI</span> will
                generate an email with the following prompt:
              </div>

              <div className="rounded-lg bg-white px-3 py-4 italic typography-body-4">
                {template.prompt}
              </div>
            </div>

            <EmailRecipientsSection
              senderEmail={senderEmail}
              errors={errors}
              touched={touched}
              onValidateEmails={handleEmailValidation}
            />

            <div className="mb-5">
              <div className="mb-2 flex items-center justify-between">
                <div className="typography-body-3-medium">Subject</div>
                {!isAIGenerationComplete && (
                  <ComposeWithAiButton onClick={onComposeWithAI} />
                )}
              </div>

              <EnhancedTextField
                name="subject"
                placeholder="Just following up on my last call"
                inputClassName="bg-white border-[#ccc] typography-body-4-medium placeholder:text-[#ccc]"
                errors={errors.subject}
                touched={touched.subject}
              />
            </div>

            <div className="mb-5">
              <EmailEditor
                className="border border-[#ccc]"
                placeholder="Dear prospect..."
                onChange={(content) => {
                  setBodyError(undefined);
                  setBodyContent(content);
                }}
                initialContent={initialBody}
                error={bodyError}
              />
            </div>

            <EmailAttachmentsSection
              attachmentOptions={attachmentOptions}
              selectedAttachments={selectedAttachments}
              onChange={handleAttachmentsChange}
            />

            <div className="divider" />

            <EmailFollowUpsSection
              className="mb-11"
              isSendFollowUps={isSendFollowUps}
              onChangeFollowUpsToggle={setIsSendFollowUps}
            />
          </section>

          <section
            className={clsxMerge(
              "flex items-center justify-end",
              "rounded-md bg-black/10",
              "gap-4 p-4"
            )}
          >
            {!isAIGenerationComplete && (
              <button
                id={QUICK_SEND_AI_EMAIL_BUTTON_ID}
                type="button"
                className="btn-nofill"
                onClick={handleQuickSend}
                disabled={!isEmailsValid}
              >
                <SparklesIcon className="mr-2 w-5" /> QUICK SEND
              </button>
            )}

            <button
              type="submit"
              className={clsxMerge("btn-ae-default min-w-[120px]", {
                loading: isSendingEmail,
                "btn-nofill pointer-events-none": isSendEmailSuccess,
              })}
              disabled={!isEmailsValid}
            >
              {isSendEmailSuccess ? (
                <span className="flex items-center gap-1">
                  SENT <CheckCircleIcon className="w-5" />
                </span>
              ) : (
                "SEND"
              )}
            </button>
          </section>
        </Form>
      )}
    </Formik>
  );
};
