import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import unionBy from "lodash/unionBy";
import { AxiosResponse } from "axios";

import { UserI } from "@/interfaces/user";
import { CampaignI, CampaignV3I } from "@/interfaces/campaign";
import {
  GetCampaignsResponseI,
  GetCampaignsV3ParamsI,
  GetCampaignsV3ResponseI,
  GetUserResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import { useEffectOnce } from "shared/lib/hooks";

import { AsyncGetI, asyncGet } from "@/helpers/context";
import { GlobalNotificationsProvider } from "@/context/notifications-context";
import { SessionStorage } from "@/helpers/session-storage";
import { UserOnboardingStatusI } from "@/interfaces/signup";

export interface DefaultGlobalContextI {
  isUserLoaded?: boolean;
  glencocoUser?: UserI;
  userOnboardingStatus?: UserOnboardingStatusI;
  campaigns?: Array<CampaignI>;
  intercom?: any;

  asyncGet?: AsyncGetI;
  setCampaigns?: Dispatch<SetStateAction<Array<CampaignI | CampaignV3I>>>;
  requestAndMergeCampaigns?: (
    params: GetCampaignsV3ParamsI
  ) => Promise<AxiosResponse<GetCampaignsV3ResponseI>>;
  reloadUser?: () => Promise<void>;
}

export interface GlobalContextI extends DefaultGlobalContextI {
  asyncGet: AsyncGetI;
  setCampaigns: Dispatch<SetStateAction<Array<CampaignI | CampaignV3I>>>;
  requestAndMergeCampaigns?: (
    params: GetCampaignsV3ParamsI
  ) => Promise<AxiosResponse<GetCampaignsV3ResponseI>>;
}

const GlobalContext = createContext<DefaultGlobalContextI>({});

const requestCampaigns = (requestParams: GetCampaignsV3ParamsI) => {
  const API = glencocoClientAPI();
  return API.getCampaignsV3(requestParams).catch((e) => e);
};

export const GlobalContextProvider = ({
  children,
  ...props
}: {
  children?: any;
  props?: DefaultGlobalContextI;
}) => {
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [user, setUser] = useState(
    (props as DefaultGlobalContextI)?.glencocoUser
  );
  const [userOnboardingStatus, setUserOnboardingStatus] = useState(
    (props as DefaultGlobalContextI)?.userOnboardingStatus
  );
  const [campaigns, setCampaigns] = useState(
    (props as DefaultGlobalContextI)?.campaigns
  );
  const [intercom, setIntercom] = useState(
    (props as DefaultGlobalContextI)?.intercom
  );

  const requestAndMergeCampaigns = async (params: GetCampaignsV3ParamsI) => {
    const GetCampaignsResponse = await requestCampaigns(params);

    if (GetCampaignsResponse.status === 200) {
      const data = GetCampaignsResponse?.data as GetCampaignsResponseI;

      setCampaigns((campaigns) => {
        const allcampaigns = unionBy(
          data.campaigns || [],
          campaigns || [],
          "id"
        );
        const SS = new SessionStorage();
        SS.campaigns = allcampaigns as unknown as Array<CampaignV3I>;

        return allcampaigns;
      });
    }

    return GetCampaignsResponse as AxiosResponse<GetCampaignsV3ResponseI>;
  };

  const reloadUser = async () => {
    const SS = new SessionStorage();
    const API = glencocoClientAPI();

    const sessionUser = SS.user;
    const sessionCampaigns = SS.campaigns;

    if (sessionUser) setUser(sessionUser);
    if (sessionCampaigns)
      setCampaigns(sessionCampaigns as unknown as Array<CampaignI>);
    if (sessionUser && sessionCampaigns) setIsUserLoaded(true);

    // NOTE - set the current user ---------------------------
    const userPromise = API.getUser()
      .then((UserResp) => {
        if (UserResp.status === 200) {
          const data = UserResp?.data as GetUserResponseI;

          // const user_onboarding_status = {
          //   picked_campaign: true,
          //   watched_intro_video: true,
          //   weekly_call_target: 1,
          // };

          SS.user = data?.user;
          setUser(data?.user);
          setIntercom(data?.intercom_payload);
          setUserOnboardingStatus(data?.user_onboarding_status);
        }
      })
      .catch((e) => e);

    // NOTE - Get campaigns user can start calling to ---------------------------
    const campaignsPromise = requestAndMergeCampaigns({
      filter: {
        custom: {
          is_approved_to_call: true,
        },
      },
    });

    Promise.all([userPromise, campaignsPromise])
      .then(() => {
        setTimeout(() => {
          setIsUserLoaded(true);
        }, 100);
      })
      .catch(() => {
        setIsUserLoaded(true);
      });
    /** ----------------------- END ----------------------- */
  };

  const [gc, setGlobalContext] = useState({
    ...props,
    setIsUserLoaded,
    setUser,
    setCampaigns,
    setIntercom,
    setUserOnboardingStatus,

    asyncGet,
    requestAndMergeCampaigns,
    reloadUser,
  } as GlobalContextI);

  useEffectOnce(() => {
    reloadUser();
  });

  useEffect(() => {
    setGlobalContext({
      ...gc,
      glencocoUser: user,
      isUserLoaded,
      campaigns,
      intercom,
      userOnboardingStatus: userOnboardingStatus,
    });
  }, [user, isUserLoaded, campaigns, intercom, userOnboardingStatus]);

  return (
    <GlobalContext.Provider value={gc}>
      <GlobalNotificationsProvider>{children}</GlobalNotificationsProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
